










































import { Component, Vue, Prop } from "vue-property-decorator";
import Segment from "@/components/project-valuation-report/Segment.vue";
import Footer from "@/components/project-valuation-report/Footer.vue";
import ProjectBuyer from "@/entity/ProjectBuyer";
import moment from "moment";
import { DATE_FORMAT } from "@/constants";

@Component({
  components: {
    Segment,
    Footer,
  },
})
export default class ProjectBuyerStatisticsReportPage3 extends Vue {
  @Prop({ type: Array, required: true }) buyers!: ProjectBuyer[];

  firstName(buyer: ProjectBuyer) {
    return buyer.contact?.firstName || "";
  }

  lastName(buyer: ProjectBuyer) {
    return buyer.contact?.lastName || "";
  }

  companyName(buyer: ProjectBuyer) {
    return buyer.contact?.company?.name || "";
  }

  buyerType(buyer: ProjectBuyer) {
    return buyer.contact?.buyerType?.name || "";
  }

  lastStatus(buyer: ProjectBuyer) {
    return moment(buyer.statusUpdatedAt * 1000).format(DATE_FORMAT);
  }

  status(status: any) {
    let res;
    console.log("status: ", status);
    if (status === "prospectstatus.expressionofinterestfilled") {
      res = "Interessen. ausgefüllt";
    } else if (status === "prospectstatus.expressionofinterestsent") {
      res = "Interessen. gesendet";
    } else {
      res = this.$tc(status);
    }
    return res;
  }

  get currentDate() {
    return moment().format(DATE_FORMAT);
  }
}
