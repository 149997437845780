

















import { DATE_FORMAT } from "@/constants";
import moment from "moment";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ProjectBuyerStatisticsReportPage1 extends Vue {
  @Prop({ required: true, type: String }) companyName!: string;

  date() {
    return moment().format(DATE_FORMAT);
  }
}
