























































































import { ProjectValuationBuyerChart } from "@/entity/project-valuation/ProjectValuationBuyers";
import { Component, Prop, Vue } from "vue-property-decorator";
import ProjectValuationBuyerTypeTiles from "@/components/project-valuation-buyers/ProjectValuationBuyerTypeTiles.vue";
import { BuyerStatisticsReportStatisticsObject } from "@/views/ProjectBuyerStatisticsReportView.vue";
import { DATE_FORMAT } from "@/constants";
import moment from "moment";
import GraphScore from "@/components/project-valuation-report/GraphScore.vue";
import Project from "@/entity/Project";

@Component({
  components: {
    ProjectValuationBuyerTypeTiles,
    GraphScore,
  },
})
export default class ProjectBuyerStatisticsReportPage2 extends Vue {
  @Prop({ type: String, required: true }) findings!: string;
  @Prop({ type: String, required: true }) nextSteps!: string;
  @Prop({ type: Array, required: true })
  buyerTypes!: ProjectValuationBuyerChart[];
  @Prop({ type: Object, required: true })
  statistics!: BuyerStatisticsReportStatisticsObject;
  @Prop({ type: Object, required: true }) dismissReasons!: Record<
    string,
    number
  >;
  @Prop({ type: Object, required: true }) project!: Project;

  get currentDate() {
    return moment().format(DATE_FORMAT);
  }

  get projectStartingDate() {
    return moment(new Date(this.project.startingDate!)).format(DATE_FORMAT);
  }

  get highestCancelationReasonNumber() {
    let highest = 0;

    for (const key in this.dismissReasons) {
      const value = this.dismissReasons[key];
      if (value > highest) highest = value;
    }

    return highest;
  }

  percentageOfN(n1: number, n2: number) {
    return (n1 * 100) / n2;
  }

  get sortedDismissedReasons() {
    return Object.keys(this.dismissReasons)
      .map((item) => ({ count: this.dismissReasons[item], name: item }))
      .sort((a, b) => b.count - a.count);
  }
}
