



















import { Component, Vue, Prop } from "vue-property-decorator";
import Page1 from "@/components/project-buyer-statistics-report/Page1.vue";
import Page2 from "@/components/project-buyer-statistics-report/Page2.vue";
import Page3 from "@/components/project-buyer-statistics-report/Page3.vue";
import Project from "@/entity/Project";
import ProjectBuyer from "@/entity/ProjectBuyer";
import { ProjectValuationBuyerChart } from "@/entity/project-valuation/ProjectValuationBuyers";
import { BuyerStatisticsReportStatisticsObject } from "@/views/ProjectBuyerStatisticsReportView.vue";

@Component({
  components: {
    Page1,
    Page2,
    Page3,
  },
})
export default class ProjectBuyerStatisticsReport extends Vue {
  @Prop({ type: Object, required: true }) project!: Project;
  @Prop({ type: String, required: true }) findings!: string;
  @Prop({ type: String, required: true }) nextSteps!: string;
  @Prop({ type: Array, required: true }) buyers!: ProjectBuyer[];
  @Prop({ type: Object, required: true }) dismissReasons!: Record<
    string,
    number
  >;
  @Prop({ type: Array, required: true })
  buyerTypes!: ProjectValuationBuyerChart[];
  @Prop({ type: Object, required: true })
  statistics!: BuyerStatisticsReportStatisticsObject;

  get buyersPagination() {
    const ITEMS_PER_PAGE = 22;
    const res: ProjectBuyer[][] = [];
    for (let i = 0; i < Math.ceil(this.buyers.length / ITEMS_PER_PAGE); i++) {
      res.push(
        this.sortedBuyers.slice(i * ITEMS_PER_PAGE, (i + 1) * ITEMS_PER_PAGE)
      );
    }
    return res;
  }

  get sortedBuyers() {
    return this.buyers.sort((a, b) =>
      this.firstName(a).toUpperCase() > this.firstName(b).toUpperCase()
        ? 1
        : this.firstName(b).toUpperCase() > this.firstName(a).toUpperCase()
        ? -1
        : 0
    );
  }

  firstName(buyer: ProjectBuyer) {
    return buyer.contact?.firstName || "";
  }

  lastName(buyer: ProjectBuyer) {
    return buyer.contact?.lastName || "";
  }
}
